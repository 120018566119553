import { useEffect, useState } from "react";
import http from "../utils/axios";

const useLookups = (endpointUrl: string) => {
  const [data, setData] = useState<any>([]);
  const [url] = useState(endpointUrl);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await http.get(url);
        setData(result.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [url]);
  return { data };
};

export default useLookups;
