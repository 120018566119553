import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const OlogaGenericCombo = ({
  required = false,
  label,
  name,
  items,
  value,
  onChange,
}) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        label={label}
        name={name}
        required={required}
        error={required && !value}
        helperText={required && !value && "This field is required"}
      >
        <MenuItem key={value.id} value={value} selected>
          {value.description || value.name}
        </MenuItem>
        {items.map(
          (item) =>
            value.id != item.id && (
              <MenuItem key={item.id} value={item}>
                {item.description || item.name}
              </MenuItem>
            )
        )}
      </Select>
    </FormControl>
  );
};

export default OlogaGenericCombo;
