import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Grid,
  Button,
  Typography,
  TextField,
  FormControl,
  Input,
} from "@mui/material";
import {
  useNavigate,
  useParams,
  useLocation,
  NavLink,
  Navigate,
} from "react-router-dom";
import React, { useState, useEffect, ChangeEvent } from "react";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
import axios from "../../utils/axios";
import Item from "../../components/Combos/Item";
import CancelButton from "../../components/Buttons/CancelButton";
import SubmitButton from "../../components/Buttons/SubmitButton";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { ToastContainer, toast } from "react-toastify";
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

function SettingsForm(props: any) {
  const disabledStyles: React.CSSProperties = {
    backgroundColor: "#f0f0f0",
    color: "#888",
    cursor: "not-allowed",
    textDecoration: "none",
  };

  const initialSettingObjData = {
    id: 0,
    description: null,
    type: {
      description: "",
    },
    parentId: null,
  };
  const { closemodal } = props;
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showLoading, setShowLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [settingObj, setSettingObj] = useState(initialSettingObjData);
  const [settingCategories, setSettingCategories] = useState<Item[]>([]);
  const [selectedType, setSelectedType] = useState("");
  const updateVariable = (object: any, propertyName: string, newValue: any) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };

    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      setSettingObj({ ...object });
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      setSettingObj({ ...currentObject });
    }
  };

  useEffect(() => {
    if (state?.settingObj?.id) {
      const updateType = {
        ...settingObj,
        id: state?.settingObj?.id,
        description: state?.settingObj?.description,
        type: { description: state?.settingObj?.type },
      };
      setSettingObj(updateType);
      setSelectedType(state?.settingObj?.type);
    } else {
      setSettingObj(initialSettingObjData);
    }
    retriveSettingCategories();
  }, [state]);

  const retriveSettingCategories = async () => {
    // remove all type if user is editing the setting, to avoid relaction data mix
    if (state?.settingObj?.id) {
    } else if (props?.type) {
      setSelectedType(props?.type);
    } else {
      const response = await axios.get("/simple-entity/setting-types");
      setSettingCategories(
        response.data.map((r: Item) => ({
          id: r.id,
          description: r.description,
          type: r.type,
          name: "type",
        }))
      );
    }
  };

  const handleSelectChange = (event: Item) => {
    updateVariable(settingObj, event.name, event);
    setSelectedType(event.type);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    updateVariable(settingObj, name, value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    try {
      event.preventDefault();

      const objToSave = {
        id: state?.settingObj?.id ? settingObj.id : 0,
        description: settingObj.description,
        type: selectedType,
        parentId: props.parentId,
      };
      const settingsCreated = await axios.post(
        "simple-entity/setting/",
        objToSave
      );

      setShowLoading(false);
      toast.success("Setting saved sucessfully");
      setShowSuccess(true);
      if (
        (event.target as any).outerText === "Save and Create New" ||
        (event.target as any).outerText === "Edit and Create New"
      ) {
        navigate("/settings/create", { replace: true });
        setSettingObj(initialSettingObjData);
      } else if ((event.target as any).outerText === "Save") {
        navigate("/settings/search", { replace: true });
      }
      // closemodal();
    } catch (error) {
      toast.error((error as any).message);
    }
  };

  return (
    <React.Fragment>
      {props?.type ? (
        <></>
      ) : (
        <Grid item xs={6}>
          <OlogaPageHeader
            title={state?.settingObj?.id ? "Edit Setting" : "Add Setting"}
            items={[{ label: "settings", url: "/settings/search" }]}
          />
        </Grid>
      )}

      <Card mb={12}>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} marginBottom={3}>
              {props?.type ? (
                <></>
              ) : (
                <Grid item xs={6}>
                  <div className="disabled-container" style={disabledStyles}>
                    <OlogaGenericCombo
                      label="Select a type"
                      name="type"
                      items={settingCategories}
                      value={settingObj.type || ""}
                      onChange={handleSelectChange}
                    />
                  </div>
                </Grid>
              )}

              <Grid item xs={props?.type ? 12 : 6}>
                <TextField
                  fullWidth
                  label="setting description"
                  name="description"
                  variant="outlined"
                  value={settingObj.description || ""}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
            </Grid>
            <Grid
              container
              marginTop={5}
              spacing={3}
              mt={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              {props?.type ? (
                <Grid item className="form-buttons">
                  <SubmitButton label="Add" onClick={handleSubmit} />
                </Grid>
              ) : (
                <>
                  <Grid item className="form-buttons">
                    <SubmitButton
                      label={state?.settingObj?.id ? "Edit" : "Save"}
                      onClick={handleSubmit}
                    />
                  </Grid>
                  <Grid item marginRight={2}>
                    <CancelButton navigateTo={"../search"} />
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
export default SettingsForm;
