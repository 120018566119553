import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Box,
  Card as MuiCard,
  CardContent,
  Grid,
  Typography,
  Fade,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { spacing } from "@mui/system";
import useLookups from "../../hooks/useLookups";
import http from "../../utils/axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import OlogaSelect from "../../components/select/OlogaSelect";
import OlogaDatePicker from "../../components/inputfields/OlogaDatePicker";
import OlogaTimePicker from "../../components/inputfields/OlogaTimePicker";

import { formatDateToDdMmYyyy, formatTime } from "../../utils/utils";
import OlogaButtonGroup from "../../components/Buttons/OlogaButtonGroup";
import { eventSchema } from "../../utils/formValidation";
import { useNavigate } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import { toast } from "react-toastify";
import { is } from "date-fns/locale";
import VenueModal from "./VenueModal";
import ToursModal from "./ToursModal";
import OlogaLoading from "../../components/misc/OlogaLoading";

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

interface EventFormProps {
  event?: any;
}

function EventForm({ event }: EventFormProps) {
  const navigate = useNavigate();
  const { data: countries } = useLookups("/locations/countries/names");
  const { data: promoters } = useLookups("/companies/promoters/names");
  const { data: vendors } = useLookups("/participations/participation/vendors");
  const [selectedVendors, setSelectedVendors] = useState<number[]>([]);
  const [venues, setVenues] = useState([]);
  const [tours, SetTours] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleCloseVenueModal = () => {
    if (event?.id) {
      handleCountryChange(event.countryId);
    }
  };

  const fetchData = async () => {
    try {
      const result = await http.get("/tours/country/names");
      SetTours(result.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleCloseTourModal = () => {
    fetchData();
  };

  const columns: GridColDef[] = [
    {
      field: "vendorName",
      headerName: "Name",
      flex: 1,
      editable: true,
    },
    {
      field: "countryName",
      headerName: "Country",
      flex: 1,
      editable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <FormControlLabel
          key={params.row.id}
          control={
            <Switch
              id={params.row.id}
              onChange={handleCheck}
              defaultChecked={checkParticipation(params.row)}
            />
          }
          label=""
        />,
      ],
    },
  ];

  const handleCountryChange = async (value: any) => {
    setVenues([]);
    try {
      const result = await http.get(`/venues/country/${value}/names`);
      setVenues(result.data);
    } catch (error: any) {}
  };

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (new Date(values.startDate) > new Date(values.endDate)) {
        toast.error("Event start date cannot be greater than end date");
        return;
      }
      setLoading(true);
      values.startDate = formatDateToDdMmYyyy(values.startDate);
      values.endDate = formatDateToDdMmYyyy(values.endDate);
      values.startTime = formatTime(values.startTime);
      values.endTime = formatTime(values.endTime);
      values.vendorIds = selectedVendors;
      if (event?.id) {
        await http.put("/events", values);
      } else {
        await http.post("/events", values);
      }

      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
      setLoading(false);
      toast.success("Event saved successfully!");
      navigate("/events");
    } catch (error: any) {
      setStatus({ sent: false });
      // setErrors({ submit: error.message });
      setSubmitting(false);
      toast.error(error.message);
    }
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const newArray = [...selectedVendors];
      newArray.push(parseInt(e.target.id));
      setSelectedVendors(newArray);
    } else {
      const newArray: number[] = selectedVendors.filter(
        (item: number) => item !== parseInt(e.target.id)
      );
      setSelectedVendors(newArray);
    }

    if (event?.id) {
      updateParticipation(parseInt(e.target.id), e.target.checked);
    }
  };

  const updateParticipation = async (vendorId: number, checked: boolean) => {
    try {
      await http.post("/participations/vendor", {
        id: event.id,
        vendorId: vendorId,
        isParticipating: checked,
      });
      toast.success("Participation updated successfully!");
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const checkParticipation = (vendor: any) => {
    if (event.id) {
      return vendor.events?.includes(Number(event.eventId));
    }
    return false;
  };

  useEffect(() => {
    fetchData();
    if (event?.id) {
      handleCountryChange(event.countryId);
      // console.log(event);
      // vendors.forEach((v: any) => {
      //   if (v.events?.includes(Number(event.eventId))) {
      //     const newArray = [...selectedVendors];
      //     newArray.push(parseInt(v.id));
      //     setSelectedVendors(newArray);
      //   }
      // });
    }
  }, [event]);

  return (
    <Formik
      initialValues={event}
      validationSchema={eventSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, status, errors }) => (
        <Card>
          <OlogaLoading open={loading} />
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Typography variant="h6" gutterBottom>
                Event
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <OlogaTextfield name="name" label="Event Name" />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={3}>
                  <OlogaSelect
                    name="countryId"
                    options={countries}
                    label="Country"
                    setValue={handleCountryChange}
                  />
                </Grid>
                <Grid item md={3}>
                  <OlogaSelect
                    name="tourId"
                    options={tours}
                    label="Country Tour"
                  />
                </Grid>
                <Grid item md={5}>
                  <OlogaSelect
                    name="promoterId"
                    options={promoters}
                    label="Promoter"
                  />
                </Grid>
                <Grid item md={1}>
                  <ToursModal
                    btnName="+"
                    title="Add Tour"
                    onClosemodal={handleCloseTourModal}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={8}>
                  <OlogaSelect name="venueId" options={venues} label="Venue" />
                </Grid>
                <Grid item md={1}>
                  <VenueModal
                    btnName="+"
                    title="Add Venue"
                    onClosemodal={handleCloseVenueModal}
                  />
                </Grid>
                <Grid item md={3}>
                  <OlogaTextfield
                    name="expectedAudience"
                    label="Expected Audience"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={3}>
                  <OlogaDatePicker
                    name="startDate"
                    label="Start Date"
                    disablePast={true}
                  />
                </Grid>
                <Grid item md={3}>
                  <OlogaTimePicker name="startTime" label="Start Time" />
                </Grid>
                <Grid item md={3}>
                  <OlogaDatePicker
                    name="endDate"
                    label="End Date"
                    disablePast={true}
                  />
                </Grid>
                <Grid item md={3}>
                  <OlogaTimePicker name="endTime" label="End Time" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <OlogaTextfield
                    name="description"
                    label="Description"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
              <Typography mt={3} variant="h6" gutterBottom>
                Vendors
              </Typography>
              <Box mb={6}>
                <OlogaDataGrid
                  url={"/participations/participation/vendors"}
                  columns={columns}
                  isPagedContent={false}
                  paginationMode="client"
                />
              </Box>
              {/* <Grid item md={3}>
                <FormGroup>
                  {vendors.map((vendor: any) => (
                    <>
                      <FormControlLabel
                        key={vendor.vendorId}
                        control={
                          <Switch
                            id={vendor.vendorId.toString()}
                            onChange={handleCheck}
                            defaultChecked={checkParticipation(vendor)}
                          />
                        }
                        label={vendor.vendorName}
                      />
                    </>
                  ))}
                </FormGroup>
              </Grid> */}
              <OlogaButtonGroup />
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default EventForm;
