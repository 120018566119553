import React from "react";
import { Button as MuiButton } from "@mui/material";
import { SpacingProps, spacing } from "@mui/system";
import styled from "@emotion/styled";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

interface OlogaButtonProps {
  type?: "submit" | "button" | "reset";
  label: string;
  variant?: "text" | "outlined" | "contained";
  size?: "small" | "medium" | "large";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  icon?: React.ReactNode;
  mr?: number;
  ml?: number;
  mt?: number;
  mb?: number;
  onClick?: (event: React.FormEvent) => void;
}

export const OlogaButton: React.FC<OlogaButtonProps> = ({
  type = "button",
  label,
  variant = "contained",
  size = "medium",
  color = "primary",
  icon,
  mr,
  ml,
  mt,
  mb,
  onClick,
}) => {
  return (
    <Button
      type={type}
      variant={variant}
      size={size}
      color={color}
      startIcon={icon}
      mr={mr}
      ml={ml}
      mt={mt}
      mb={mb}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};
