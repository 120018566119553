import { Card, CardContent, Grid } from "@mui/material";

import VenueForm from "./VenueForm";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";

function CreateVenue() {
  return (
    <>
      <OlogaPageHeader
        title="Add Venue"
        items={[
          { label: "Events", url: "/events" },
          { label: "Venues", url: "/events/venues" },
        ]}
      />
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <VenueForm
                venue={{
                  name: "",
                  address: "",
                  description: "",
                  countryId: "",
                  id: "",
                  cityId: "",
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateVenue;
