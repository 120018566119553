import React, { Suspense, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Card as MuiCard, Grid, Fade, CircularProgress } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useNavigate } from "react-router-dom";
import { revertDate, revertTime } from "../../utils/utils";
import useAuth from "../../hooks/useAuth";

import { Formik } from "formik";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import OlogaSelect from "../../components/select/OlogaSelect";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import SearchIcon from "@mui/icons-material/Search";
import useLookups from "../../hooks/useLookups";
import { toast } from "react-toastify";

const Card = styled(MuiCard)(spacing);

function EventList() {
  const { data: promoters } = useLookups("/companies/promoters/names");
  const { data: cities } = useLookups("/locations/cities/names");
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState("");
  // const [url, setUrl] = useState("/eventProduct/q?");
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isVendor, setIsVendor] = useState(true);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      editable: true,
    },
    {
      field: "promoterName",
      headerName: "Promoter Name",
      flex: 1,
      editable: false,
    },
    {
      field: "cityName",
      headerName: "City",
      flex: 1,
      editable: false,
    },
    {
      field: "venueName",
      headerName: "Venue",
      flex: 1,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 0.5,
      editable: false,
    },
    {
      field: "startTime",
      headerName: "Start Time",
      flex: 0.5,
      editable: false,
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 0.5,
      editable: false,
    },
    {
      field: "endTime",
      headerName: "End Time",
      flex: 0.5,
      editable: false,
    },
    {
      field: "authCode",
      headerName: "Auth Code",
      flex: 0.5,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate(
              isVendor ? "../events/manage-event" : `../events/edit-event`,
              {
                state: {
                  event: {
                    ...params.row,
                    startDate: revertDate(params.row.startDate),
                    endDate: revertDate(params.row.endDate),
                    startTime: revertTime(params.row.startTime),
                    endTime: revertTime(params.row.endTime),
                  },
                },
              }
            );
          }}
          label="Edit"
        />,
      ],
    },
  ];

  const handleSubmit = async (values: any) => {
    try {
      setUrl("");
      let q = "";
      for (let key in values) {
        if (values.hasOwnProperty(key)) {
          let value = values[key];
          if (value) {
            q += key + "=" + value + "&";
          }
        }
      }
      if (isVendor) {
        setUrl(
          q.length > 0
            ? "/events/search/vendor/q?" + q.slice(0, -1)
            : "/events/search/vendor/q?"
        );
      } else {
        setUrl(q.length > 0 ? "/events/q?" + q.slice(0, -1) : "/events/q?");
      }
    } catch (error: any) {
      toast.error(error.message || "Error submitting form");
    }
  };

  useEffect(() => {
    if (user != null) {
      let isUserVendor =
        user?.roleName === "ROLE_VENDOR" ||
        user?.roleName === "ROLE_VENDOR_SUPERVISOR"
          ? true
          : false;
      setUrl(isUserVendor ? `/events/vendor/${user?.vendorId}` : "/events/q");
      setIsVendor(isUserVendor);
      setLoading(false);
    }
  }, [user]);

  // useEffect(() => {
  //   console.log(url);
  // }, [url]);

  return (
    <React.Fragment>
      <OlogaPageHeader
        title="Events"
        buttonLabel={isVendor ? "" : "New Event"}
        onClick={() => navigate("../events/add-event")}
      />

      <Grid container>
        <Grid item xs={12}>
          <Formik initialValues={{}} enableReinitialize onSubmit={handleSubmit}>
            {({ handleSubmit, status }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4} mb={3}>
                  <Grid item xs={12} md={3}>
                    <OlogaTextfield name="name" label="Event Name" />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <OlogaSelect
                      name="promoterId"
                      label="Promoter"
                      options={promoters}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <OlogaSelect name="cityId" label="City" options={cities} />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <OlogaButton
                      label="Search"
                      type="submit"
                      icon={<SearchIcon />}
                      mt={3}
                      size="large"
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Card>
            <Fade in={loading} unmountOnExit>
              <CircularProgress />
            </Fade>
            {!loading && (
              <OlogaDataGrid
                url={url}
                isPagedContent={!isVendor}
                paginationMode={isVendor ? "client" : "server"}
                columns={columns}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EventList;
