import styled from "@emotion/styled";

import { Card as MuiCard, Grid } from "@mui/material";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { spacing } from "@mui/system";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useNavigate } from "react-router";

const Card = styled(MuiCard)(spacing);

function Orders() {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Order #",
      flex: 0.3,
      editable: false,
    },
    {
      field: "event",
      headerName: "Event",
      flex: 1,
      editable: false,
    },
    {
      field: "clientName",
      headerName: "Client",
      flex: 1,
      editable: false,
    },
    {
      field: "transactionDate",
      headerName: "Order Date",
      flex: 1,
      editable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      editable: false,
    },
    {
      field: "shippingCost",
      headerName: "Shipping Cost",
      flex: 1,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate("/finance/orderdetail", {
              state: {
                id: params.id,
              },
            });
          }}
          label="Details"
        />,
      ],
    },
  ];
  return (
    <>
      <OlogaPageHeader title="Orders" />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid url={"/orders/search/q"} columns={columns} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Orders;
