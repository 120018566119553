import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Card as MuiCard, Grid, Autocomplete, TextField } from "@mui/material";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { spacing } from "@mui/system";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useNavigate } from "react-router";
import useLookups from "../../hooks/useLookups";

const Card = styled(MuiCard)(spacing);

function Sales() {
  const [transactionCode, setTransactionCode] = useState("");
  const [url, setUrl] = useState("");
  const { data: events } = useLookups("/events/names");

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "event",
      headerName: "Event",
      flex: 1,
      editable: true,
    },
    {
      field: "transactionDate",
      headerName: "Transaction Date",
      flex: 1,
      editable: false,
    },
    {
      field: "merchant",
      headerName: "Merchant",
      flex: 1,
      editable: false,
    },
    {
      field: "payments",
      headerName: "Payment Types",
      flex: 1,
      editable: false,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate("/finance/saledetail", {
              state: {
                transactionCode: params.id,
              },
            });
          }}
          label="Details"
        />,
      ],
    },
  ];

  useEffect(() => {}, [url]);

  return (
    <>
      <OlogaPageHeader title="Sales" />
      <Grid justifyContent="space-between" container spacing={6} mb={4}>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={events}
            getOptionLabel={(option: any) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select the event"
                variant="outlined"
              />
            )}
            onChange={(event, value) => {
              setUrl(`/sales/search/q?event.id=${value?.id}`);
            }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid url={url} columns={columns} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Sales;
