import { Card, CardContent, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import UserForm from "./UserForm";

function UpdateUser() {
  const { state } = useLocation();

  return (
    <>
      <OlogaPageHeader
        title="User"
        items={[{ label: "Users", url: "/users" }]}
      />
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <UserForm
                user={{
                  ...state.user,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default UpdateUser;
