import styled from "@emotion/styled";

import { Card as MuiCard, Grid, CardContent } from "@mui/material";
import { spacing } from "@mui/system";

import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import CountryTourForm from "./CountryTourForm";

const Card = styled(MuiCard)(spacing);

function CreateCountryTour() {
  return (
    <>
      <OlogaPageHeader
        title="Add Country Tour"
        items={[{ label: "Country Tours", url: "/events/country-tours" }]}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <CountryTourForm
                tour={{
                  id: null,
                  name: "",
                  worldTourId: "",
                  countryId: "",
                  startDate: "",
                  endDate: "",
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateCountryTour;
