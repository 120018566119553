import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";

const SignOut = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  useEffect(() => {
    handleSignOut();
  }, []);

  const handleSignOut = async () => {
    await signOut();
    navigate("/");
  };

  return <></>;
};

export default SignOut;
