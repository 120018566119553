import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SubmitButton from "../../components/Buttons/SubmitButton";
import Item from "../../components/Combos/Item";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import useLookups from "../../hooks/useLookups";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import useAuth from "../../hooks/useAuth";

const eventProductHeaders = [
  { header: "ID", key: "id" },
  { header: "Imagem", key: "imageUri" },
  { header: "Descrição do Produto", key: "productDescription" },
  { header: "Origin Station Quantity", key: "originQuantity" },
  { header: "Destination Station Quantity", key: "destinationQuantity" },
  { header: "Quantity", key: "quantity" },
];

const StockReconcilePage: React.FC<any> = ({}) => {
  const initialFormData = {
    vendor: null,
    event: null,
    station: null,
  };

  const { data: vendors } = useLookups("/companies/vendors/user");
  const [vendorEvents, setVendorEvents] = useState([]);
  const [eventProducts, setEventProducts] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [productSizes, setProductSizes] = useState([]);
  const [stations, setStations] = useState([]);
  const { user: userContext } = useAuth();

  useEffect(() => {
    if (userContext?.roleName != "ROLE_ADMIN")
      getVendorEvents(userContext?.vendorId);
  }, [userContext]);

  const getVendorEvents = async (vendorId: string) => {
    setVendorEvents([]);
    if (vendorId) {
      try {
        const response = await axios.get(`/events/vendorx/${vendorId}`);
        setVendorEvents(response.data);
      } catch (error) {}
    }
  };

  const getStations = async (eventId: string, vendorId: string) => {
    setStations([]);
    if (eventId && vendorId) {
      try {
        const response = await axios.get(
          `/stations/event/${eventId}/vendor/${vendorId}/names`
        );
        setStations(response.data);
      } catch (error) {}
    }
  };

  const getEventProductStock = async (
    eventId: string,
    vendorId: string,
    stationId: string
  ) => {
    setEventProducts([]);
    const response = await axios.get(
      `/stock/reconcile/event/${eventId}/vendor/${vendorId}/station/${stationId}`
    );
    setEventProducts(
      response.data.map((r: any) => {
        return {
          eventProductId: r.eventProductId,
          productDescription: r.productDescription,
          stationName: r.stationName,
          stationId: r.stationId,
          imageUri: r.imageUri,
          productSizeDescription: r.productSizeDescription,
          productSizeId: r.productSizeId,
          openingStock: r.openingStock,
          transferIn: r.transferIn,
          transferOut: r.transferOut,
          finalOpeningStock: r.finalOpeningStock,
          finalStockManual: r.finalStockManual,
          closingStock: r.closingStock,
          quantitySold: r.quantitySold,
        };
      })
    );
  };

  const handleChangeVendor = async (e: Item) => {
    updateVariable(formData, "vendor", e);
    getVendorEvents(e.id.toString());
  };

  const handleChangeEvent = async (e: Item) => {
    updateVariable(formData, "event", e);
    getStations(
      e.id.toString(),
      formData?.vendor ? (formData?.vendor as any).id : userContext?.vendorId
    );
  };

  const handleChangeStation = async (e: Item) => {
    updateVariable(formData, "station", e);
    getEventProductStock(
      (formData.event as any).id,
      formData?.vendor ? (formData?.vendor as any).id : userContext?.vendorId,
      e.id.toString()
    );
  };

  const setTableFields = (e: any) => {
    const editedProducts = eventProducts.map((item: any) =>
      item.eventProductId +
        "-" +
        item.stationId +
        "-" +
        (item.productSizeId || "") ===
        e.target.id && e.target.name
        ? { ...item, [e.target.name]: e.target.value }
        : item
    );
    setEventProducts(editedProducts as any);
  };

  const onSubmit = async () => {
    try {
      const response = await axios.post("/stock/reconcile", eventProducts);
      toast.success("Stock reconcile saved sucessfully");
    } catch (error) {
      toast.error((error as any).message);
    }
  };

  const updateVariable = (object: any, propertyName: string, newValue: any) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };

    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      setFormData({ ...object });
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      setFormData({ ...currentObject });
    }
  };

  return (
    <React.Fragment>
      <OlogaPageHeader
        title="Stock Reconcile"
        items={[{ label: "Products", url: "/products/search" }]}
      />
      <Card>
        <CardContent>
          <form onSubmit={onSubmit}>
            <Grid container spacing={2} marginBottom={3}>
              {userContext?.roleName == "ROLE_ADMIN" && (
                <Grid item xs={6}>
                  <OlogaGenericCombo
                    name="vendor"
                    label="Vendors"
                    items={vendors}
                    value={formData.vendor || ""}
                    onChange={handleChangeVendor}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <OlogaGenericCombo
                  name="event"
                  label="Events"
                  items={vendorEvents}
                  value={formData.event || ""}
                  onChange={handleChangeEvent}
                />
              </Grid>
              <Grid item xs={6}>
                <OlogaGenericCombo
                  name="station"
                  label="Station"
                  items={stations}
                  value={formData.station || ""}
                  onChange={handleChangeStation}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Station Name</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Opening Stock</TableCell>
                    <TableCell>Transfer In</TableCell>
                    <TableCell>Transfer Out</TableCell>
                    <TableCell>Final Opening Stock</TableCell>
                    <TableCell>Manual Closing Stock </TableCell>
                    <TableCell>Closing Stock</TableCell>
                    <TableCell>Quantity Sold</TableCell>
                    <TableCell>Diference</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventProducts.map(
                    ({
                      eventProductId,
                      imageUri,
                      productDescription,
                      productSizeId,
                      productSizeDescription,
                      stationId,
                      stationName,
                      openingStock,
                      transferIn,
                      transferOut,
                      finalOpeningStock,
                      finalStockManual,
                      closingStock,
                      quantitySold,
                    }) => (
                      <TableRow
                        key={
                          eventProductId +
                          "" +
                          (productSizeId || "") +
                          (stationId || "")
                        }
                      >
                        <TableCell width={30}>
                          <img
                            src={imageUri}
                            width="70px"
                            height="auto"
                            alt="product-img"
                          />
                        </TableCell>
                        <TableCell>{productDescription}</TableCell>
                        <TableCell>{stationName}</TableCell>
                        <TableCell>{productSizeDescription || "N/A"}</TableCell>
                        <TableCell>{openingStock || "0"}</TableCell>
                        <TableCell>{transferIn || "0"}</TableCell>
                        <TableCell>{transferOut || "0"}</TableCell>
                        <TableCell>{finalOpeningStock || "0"}</TableCell>
                        <TableCell>
                          <TextField
                            id={
                              eventProductId +
                              "-" +
                              stationId +
                              "-" +
                              (productSizeId || "")
                            }
                            name={"finalStockManual"}
                            value={finalStockManual || ""}
                            label={"Manual Closing Stock"}
                            onChange={setTableFields}
                          />
                        </TableCell>
                        <TableCell>{closingStock || "0"}</TableCell>
                        <TableCell>{quantitySold || "0"}</TableCell>
                        <TableCell>{finalStockManual - closingStock}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <Grid
              container
              marginTop={5}
              spacing={3}
              mt={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              {" "}
              <Grid item marginLeft={2} className="form-buttons">
                <SubmitButton label={"Save"} onClick={onSubmit} />
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default StockReconcilePage;
