import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import http from "../../utils/axios";

function OlogaDataTableSimple(props: { url: string; columns: any }) {
  const [data, setData] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const getData = async () => {
    try {
      const response = await http.get(`${props.url}`);
      setData(response.data.content);
      setTotalElements(response.data.totalElements);
    } catch (error) {}
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
    setIsLoading(false);
  }, [paginationModel]);

  return (
    <DataGrid
      autoHeight
      rows={data}
      columns={props.columns}
      rowCount={totalElements}
      pageSizeOptions={[5, 10, 25, 50, 75, 100]}
      loading={isLoading}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      checkboxSelection
    />
  );
}

export default OlogaDataTableSimple;
