import React from "react";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Card as MuiCard, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import { revertDate } from "../../utils/utils";

const WorldTourList: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: false,
    },
    {
      field: "artist",
      headerName: "Artist",
      flex: 1,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      editable: false,
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      editable: false,
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate(`/events/edit-world-tour`, {
              state: {
                tour: {
                  ...params.row,
                  startDate: revertDate(params.row.startDate),
                  endDate: revertDate(params.row.endDate),
                },
              },
            });
          }}
          label="Edit"
        />,
      ],
    },
  ];

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={"World Tours"}
        buttonLabel="New World Tour"
        onClick={() => navigate("/events/add-world-tour")}
      ></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid url={"tours/world/q"} columns={columns} />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default WorldTourList;
