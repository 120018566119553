import React from "react";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Card as MuiCard, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";

const PromoterList: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: false,
    },
    {
      field: "countryName",
      headerName: "Country",
      flex: 1,
      editable: false,
    },
    {
      field: "cityName",
      headerName: "City",
      flex: 1,
      editable: false,
    },
    {
      field: "representativeName",
      headerName: "Representative",
      flex: 1,
      editable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      flex: 1,
      editable: false,
    },
    {
      field: "representativeEmail",
      headerName: "Email",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate(`/events/edit-promoter`, {
              state: {
                company: {
                  ...params.row,
                },
              },
            });
          }}
          label="Edit"
        />,
      ],
    },
  ];

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={"Promoters"}
        buttonLabel="New promoter"
        onClick={() => navigate("/events/add-promoter")}
      ></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid url={"companies/promoters/q"} columns={columns} />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PromoterList;
