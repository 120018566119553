import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { OlogaButton } from "../Buttons/OlogaButton";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface BreadcrumbItems {
  label: string;
  url: string;
}

interface OlogaPageHeaderProps {
  title: string;
  lastElement?: string;
  items?: BreadcrumbItems[];
  buttonLabel?: string;
  onClick?: (event: React.FormEvent) => void;
  icon?: React.ReactNode;
}

const OlogaPageHeader = ({
  title,
  items = [],
  lastElement,
  buttonLabel,
  icon = <AddCircleIcon />,
  onClick,
}: OlogaPageHeaderProps) => {
  return (
    <>
      <Helmet title={title} />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {title}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/dashboard">
              Home
            </Link>
            {items.map((item) => (
              <Link component={NavLink} to={item.url} key={item.label}>
                {item.label}
              </Link>
            ))}
            <Typography>{lastElement ? lastElement : title}</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item>
          {buttonLabel && (
            <OlogaButton label={buttonLabel} icon={icon} onClick={onClick} />
          )}
        </Grid>
      </Grid>

      <Divider my={6} />
    </>
  );
};

export default OlogaPageHeader;
