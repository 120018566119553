import React from "react";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers";
import { TextFieldProps } from "@mui/material";
import { useField, useFormikContext } from "formik";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

interface OlogaTimePickerProps {
  name: string;
  label: string;
}

const TimePicker = styled(MuiTimePicker)<{ my?: number }>(spacing);

const OlogaTimePicker: React.FC<OlogaTimePickerProps> = ({ name, label }) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const [field, meta] = useField(name);

  const configTimePicker: TextFieldProps = {
    ...field,
    fullWidth: true,
    variant: "outlined",
  };

  return (
    <TimePicker
      label={label}
      format="HH:mm"
      value={values[name]}
      my={2}
      onChange={(value: any) => setFieldValue(name, value, true)}
      slotProps={{
        textField: {
          ...configTimePicker,
          error: meta.touched && Boolean(meta.error),
          helperText: meta.touched && meta.error,
        },
      }}
    />
  );
};

export default OlogaTimePicker;
