import React, { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import {
  Button,
  CardContent,
  Grid,
  Input,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
} from "@mui/material";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import OlogaDataTableSimple from "../../components/tables/OlogaDataTableSimple";
import axios from "../../utils/axios";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
import SubmitButton from "../../components/Buttons/SubmitButton";
import Item from "../../components/Combos/Item";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

interface ExcelData {
  columnName1: string;
  columnName2: number;
}

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function TransactionFixer() {
  const [event, setEvent] = useState();
  const [items, setItems] = useState([]);
  const [events, setEvents] = useState([{ id: 0, name: "" }]);
  const [eventId, setEventId] = useState(0);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [data, setData] = useState<ExcelData[] | null>(null);
  const [fixerStats, setfixerStats] = useState({
    totalLines: 0,
    totalApproved: 0,
    totalFailed: 0,
    totalSuccess: 0,
    totalIgnored: 0,
  });

  const handleEventChange = (event: Item) => {
    setEvent(event as any);
    setEventId(Number(event.id));
  };

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          // Convert array buffer to string
          const binaryString = event.target.result as ArrayBuffer;
          const workbook = read(new Uint8Array(binaryString));
          const sheetName = workbook.SheetNames[0];
          const sheetData = utils.sheet_to_json(
            workbook.Sheets[sheetName]
          ) as ExcelData[];
          setData(sheetData);
        }
      };

      reader.readAsArrayBuffer(selectedFile);
    }
  };

  const handleFileUpload = async () => {
    try {
      const response = await axios.post(`banktx/import/${eventId}`, data);
      getData();
      toast.success("File uploaded sucessfully");
    } catch (error) {
      toast.error((error as any).message);
    }
  };

  const handleTxFixer = async () => {
    try {
      const response = await axios.post(`banktx/fix/${eventId}`);

      setShow(false);
      fixerStats.totalSuccess = response.data.totalSuccess;
      fixerStats.totalIgnored = response.data.totalIgnored;
      setfixerStats(fixerStats);
      setShow(true);

      toast.success("Process executed sucessfully");
    } catch (error) {
      toast.error((error as any).message);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "totalLines",
      headerName: "Total Lines",
      flex: 1,
      editable: true,
    },
    {
      field: "totalApproved",
      headerName: "Total Aprooved",
      flex: 1,
      editable: false,
    },
    {
      field: "totalFailed",
      headerName: "Total Failed",
      flex: 1,
      editable: false,
    },
  ];

  useEffect(() => {
    retriveEvents();
  }, []);

  function EmptyCard() {
    return <Card mb={6}></Card>;
  }

  const getData = async () => {
    const url = "";

    try {
      setShow(false);

      const fixerStats = {
        totalLines: data?.length,
        totalApproved: data?.filter((x: any) => x.state === "APPROVED").length,
        totalFailed: data?.filter((x: any) => x.state === "DECLINED").length,
      };

      setfixerStats(fixerStats as any);
      setShow(true);
    } catch (error) {}
  };

  const retriveEvents = async () => {
    const response = await axios.get(`/events/names`);
    setEvents(
      response.data.map((r: Item) => {
        return {
          id: r.id,
          description: r.name,
          type: r.type,
          name: "event",
        };
      })
    );
  };

  return (
    <React.Fragment>
      <OlogaPageHeader title="Transaction Fixer" />

      <Card mb={12}>
        <CardContent>
          <form>
            <Grid container spacing={2} marginBottom={3}>
              <Grid item xs={3}>
                <OlogaGenericCombo
                  label="Event"
                  name="event"
                  items={events}
                  value={event || ""}
                  onChange={handleEventChange}
                />
              </Grid>
            </Grid>
            <br />
            <Grid item xs={6}>
              <div>
                <Input
                  type="file"
                  inputProps={{ accept: ".xlsx, .xls" }}
                  onChange={handleFileChange}
                />
              </div>
            </Grid>

            <Divider my={6} />

            <Grid
              container
              marginTop={5}
              spacing={3}
              mt={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Grid item marginRight={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleFileUpload()}
                >
                  Upload
                </Button>{" "}
              </Grid>
              <Grid item marginLeft={2} className="form-buttons">
                <SubmitButton
                  label={"Fix Transactions"}
                  onClick={() => handleTxFixer()}
                />
              </Grid>
            </Grid>

            <Divider my={6} />

            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card mb={6}>
                  <Paper>
                    {show ? (
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">
                                Statement Loaded Transactions
                              </TableCell>
                              <TableCell align="left">
                                Statement Approved Transactions
                              </TableCell>
                              <TableCell align="left">
                                Statement Failed Transactions
                              </TableCell>
                              <TableCell align="left">
                                Pagamio Fixed Transactions
                              </TableCell>
                              <TableCell align="left">
                                Pagamio Ignored Transactions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {fixerStats.totalLines}
                              </TableCell>
                              <TableCell align="left">
                                {fixerStats.totalApproved}
                              </TableCell>
                              <TableCell align="left">
                                {fixerStats.totalFailed}
                              </TableCell>
                              <TableCell align="left">
                                {fixerStats.totalSuccess}
                              </TableCell>
                              <TableCell align="left">
                                {fixerStats.totalIgnored}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <EmptyCard />
                    )}{" "}
                  </Paper>
                </Card>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default TransactionFixer;
