import styled from "@emotion/styled";
import {
  Card as MuiCard,
  CardContent,
  LinearProgress as MuiLinearProgress,
  Typography,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import { DollarSign, Icon } from "react-feather";

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Card = styled(MuiCard)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

interface OlogaStatsProps {
  title: string;
  description?: string;
  value: any;
  progress?: number;
  icon?: any;
}

const OlogaStats = ({
  title,
  description,
  value,
  progress = 100,
  icon,
}: OlogaStatsProps) => {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            <Box fontWeight="fontWeightRegular">{value}</Box>
          </Typography>
          <Typography variant="caption" gutterBottom>
            <Box fontWeight="fontWeightRegular">{description}</Box>
          </Typography>
          <Typography variant="h6" gutterBottom mt={3} mb={0}>
            {title}
          </Typography>

          <StatsIcon>{icon}</StatsIcon>
          <LinearProgress
            variant="determinate"
            value={progress}
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default OlogaStats;
