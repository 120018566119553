import React from "react";
import Button from "@mui/material/Button";

interface SubmitButtonProps {
  label: string;
  onClick: (event: React.FormEvent) => void;
  variant?: "text" | "outlined" | "contained";
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  label,
  onClick,
  variant = "contained",
}) => {
  return (
    <Button variant={variant} color="primary" onClick={onClick}>
      {label}
    </Button>
  );
};

export default SubmitButton;
