import React from "react";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { TextFieldProps } from "@mui/material";
import { useField, useFormikContext } from "formik";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface OlogaDatePickerProps {
  name: string;
  label: string;
  disablePast?: boolean;
}

const DatePicker = styled(MuiDatePicker)<{ my?: number }>(spacing);

const OlogaDatePicker: React.FC<OlogaDatePickerProps> = ({
  name,
  label,
  disablePast,
}) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const [field, meta] = useField(name);

  const configDatePicker: TextFieldProps = {
    ...field,
    fullWidth: true,
    variant: "outlined",
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        format="dd/MM/yyyy"
        value={values[name]}
        disablePast={disablePast}
        my={2}
        onChange={(value: any) => setFieldValue(name, value, true)}
        slotProps={{
          textField: {
            ...configDatePicker,
            error: meta.touched && Boolean(meta.error),
            helperText: meta.touched && meta.error,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default OlogaDatePicker;
