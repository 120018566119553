import styled from "@emotion/styled";

import {
  Card as MuiCard,
  Grid,
  CardContent,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import EventForm from "./EventForm";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import OlogaTabPanel from "../../components/misc/OlogaTab";
import StationForm from "../station/StationForm";
import MerchantAllocation from "./MerchantAllocation";
import useAuth from "../../hooks/useAuth";
import CloneEventDialog from "./CloneEventDialog";

const Card = styled(MuiCard)(spacing);

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function UpdateEvent() {
  const { user } = useAuth();
  const [value, setValue] = useState(0);
  const [isPromoter, setIsPromoter] = useState(
    user?.roleName === "ROLE_PROMOTER"
  );
  const [openDialog, setOpenDialog] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { state } = useLocation();
  return (
    <>
      <OlogaPageHeader
        title={`Edit Event: ${state.event?.name}`}
        items={[{ label: "Events", url: "/events" }]}
        buttonLabel={"Clone Event"}
        icon={<FileCopyIcon />}
        onClick={() => setOpenDialog(true)}
      />
      <CloneEventDialog
        eventId={state.event?.id}
        eventName={state.event?.name}
        open={openDialog}
        setOpen={setOpenDialog}
        // handleConfirm={deleteStation}
      />
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Event" {...a11yProps(0)} />

                    <Tab
                      label="Stations"
                      {...a11yProps(1)}
                      disabled={isPromoter}
                    />
                    <Tab
                      label="Merchants"
                      {...a11yProps(2)}
                      disabled={isPromoter}
                    />
                  </Tabs>
                </Box>
                <OlogaTabPanel value={value} index={0}>
                  <EventForm
                    event={{
                      ...state.event,
                    }}
                  />
                </OlogaTabPanel>
                {!isPromoter && (
                  <>
                    <OlogaTabPanel value={value} index={1}>
                      <StationForm
                        station={{
                          id: "",
                          eventId: "",
                          eventName: "",
                          geoLocation: "",
                          name: "",
                          supervisorId: "",
                          supervisorName: "",
                          vendorId: "",
                          vendorName: "",
                          venueName: "",
                          submit: false,
                        }}
                        isVendor={false}
                      />
                    </OlogaTabPanel>
                    <OlogaTabPanel value={value} index={2}>
                      <MerchantAllocation showBreadcrumb={false} />
                    </OlogaTabPanel>
                  </>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default UpdateEvent;
