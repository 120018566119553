import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import {
  CardContent,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
} from "@mui/material";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import OlogaDataTableSimple from "../../components/tables/OlogaDataTableSimple";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import http from "../../utils/axios";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
import SubmitButton from "../../components/Buttons/SubmitButton";
import Item from "../../components/Combos/Item";
import axios from "axios";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function BankRecon() {
  const [event, setEvent] = useState();
  const [items, setItems] = useState([]);
  const [events, setEvents] = useState([{ id: 0, name: "" }]);
  const [uri, setUri] = useState("/transactions/bank-reconcile/q?");
  const [eventId, setEventId] = useState(0);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: "Merchant Username",
      flex: 1,
      editable: true,
    },
    {
      field: "name",
      headerName: "Merchant Name",
      flex: 1,
      editable: false,
    },
    {
      field: "totalPagamio",
      headerName: "Total Sales on PagamioApp",
      flex: 1,
      editable: false,
    },
    {
      field: "totalBank",
      headerName: "Total Sales on POS",
      flex: 1,
      editable: false,
    },
    {
      field: "diference",
      headerName: "Diference",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate("/finance/recondetail", {
              state: {
                username: params.id,
                eventId: eventId,
                backRoute: "/finance/recon",
              },
            });
          }}
          label="Detail"
        />,
      ],
    },
  ];

  function EmptyCard() {
    return <Card mb={6}></Card>;
  }

  const handleChange = (event: Item) => {
    setEvent(event as any);
    setEventId(Number(event.id));
    setUri("/transactions/bank-reconcile/q?eventId=" + event.id);
  };

  const getData = async (url: string) => {
    try {
      setShow(false);
      const response = await http.get(url);
      setItems(response.data.content);
      setShow(true);
    } catch (error) {}
  };

  const retriveEvents = async () => {
    const response = await http.get(`/events/names`);
    setEvents(
      response.data.map((r: Item) => {
        return {
          id: r.id,
          description: r.name,
          type: r.type,
          name: "event",
        };
      })
    );
  };

  useEffect(() => {
    retriveEvents();
  }, []);

  return (
    <React.Fragment>
      <OlogaPageHeader title="Reconciliation" />

      <Card mb={12}>
        <CardContent>
          <form>
            <Grid container spacing={2} marginBottom={3}>
              <Grid item xs={6}>
                <OlogaGenericCombo
                  label="Event"
                  name="event"
                  items={events}
                  value={event || ""}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <SubmitButton label={"Search"} onClick={() => getData(uri)} />
            </Grid>

            <Divider my={6} />

            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card mb={6}>
                  <Paper>
                    {show ? (
                      <OlogaDataTableSimple url={uri} columns={columns} />
                    ) : (
                      <EmptyCard />
                    )}{" "}
                  </Paper>
                </Card>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default BankRecon;
