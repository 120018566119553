import { Card, CardContent, Grid, Paper, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import StationForm from "./StationForm";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";

function UpdateStation() {
  const { state } = useLocation();

  return (
    <>
      <OlogaPageHeader
        title="Station"
        items={[
          { label: "Events", url: "/events" },
          { label: "Stations", url: "/events/stations" },
        ]}
      />
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <StationForm
                station={{
                  ...state.station,
                }}
                vendorId={state.station.vendorId}
                isVendor={false}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default UpdateStation;
