import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Item from "../../components/Combos/Item";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import axios from "../../utils/axios";

import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import useLookups from "../../hooks/useLookups";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import useAuth from "../../hooks/useAuth";
import { GridActionsCellItem } from "@mui/x-data-grid";
import StockDetailModal from "./StockDetailModal";
import { WorkBook, utils, writeFile } from "xlsx";

const StockList: React.FC<any> = () => {
  const initialFormData = {
    stationVendor: null,
    stationEvent: null,
    station: null,
  };

  const { data: vendors } = useLookups("/companies/vendors/user");
  const [vendorEvents, setVendorEvents] = useState([]);
  const [eventProducts, setEventProducts] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedEventProductMetadata, setSelectedEventProductMetadata] =
    useState<any>({});
  const [selectedEventId, setSelectedEventId] = useState("");
  const [selectedEventName, setSelectedEventName] = useState("");
  const { user: userContext, isAdmin, isVendor } = useAuth();
  const [stations, setStations] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState(
    userContext?.vendorId
  );
  const [selectedStationId, setSelectedStationId] = useState("");

  useEffect(() => {
    if (isVendor) {
      getVendorEvents(userContext?.vendorId);
    }
  }, [userContext]);

  const getVendorEvents = async (vendorId: string) => {
    setVendorEvents([]);
    if (vendorId) {
      try {
        const response = await axios.get(`/events/vendorx/${vendorId}`);
        setVendorEvents(response.data);
      } catch (error) {}
    }
  };

  const getStations = async (eventId: string, vendorId: string) => {
    setStations([]);
    if (eventId && vendorId) {
      try {
        const response = await axios.get(
          `/stations/event/${eventId}/vendor/${vendorId}/names`
        );
        setStations(response.data);
      } catch (error) {}
    }
  };

  const exportReport = async () => {
    if (selectedEventId) {
      try {
        const response = await axios.get(
          `/stock/reconcile/event/${selectedEventId}/vendor/${selectedVendorId}`
        );

        const ws = utils.json_to_sheet(
          response.data.map((r: any) => {
            return {
              id: r.eventProductId,
              name: r.productDescription,
              size: r.productSizeDescription,
              stationName: r.stationName,
              openingStock: r.openingStock,
              transferIn: r.transferIn,
              transferOut: r.transferOut,
              finalOpeningStock: r.finalOpeningStock,
              manualClosingStock: r.finalStockManual,
              pagamioClosingStock: r.closingStock,
              qtySold: r.quantitySold,
            };
          })
        );

        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Stock Report");
        const now = new Date();

        const fileName = "stock_list_" + now.toISOString() + ".xlsx";
        writeFile(wb, fileName);
      } catch (error) {}
    }
  };

  const getEventProductStock = async (
    eventId: string,
    vendorId: string,
    stationId: string
  ) => {
    setEventProducts([]);
    const response = await axios.get(
      `/stock/reconcile/aggregated/event/${eventId}/vendor/${vendorId}`
    );
    console.log(response.data);
    setEventProducts(
      response.data.map((r: any) => {
        return {
          id: r.eventProductId,
          name: r.productDescription,
          stationName: r.stationName,
          imageUri: r.imageUri,
          sizeDescription: r.productSizeDescription,
          stockIn: r.transferIn,
          stockOut: r.transferOut,
          finalManualStockCount: r.finalStockManual,
          finalStockCountPagamio: r.closingStock,
          stockCountDifference: r.closingStock - r.finalStockManual,
        };
      })
    );
  };

  const handleChangeVendor = async (e: Item) => {
    updateVariable(formData, "stationVendor", e);
    getVendorEvents(e.id.toString());
    setSelectedVendorId(e.id.toString());
  };

  const handleChangeEvent = async (e: Item) => {
    updateVariable(formData, "stationEvent", e);
    setSelectedEventId(e.id.toString());
    setSelectedEventName(e.name);
    getEventProductStock(e.id.toString(), selectedVendorId, selectedStationId);
    getStations(
      e.id.toString(),
      userContext?.isAdmin
        ? (formData?.stationVendor as any).id
        : userContext?.vendorId
    );
  };

  const handleChangeStation = async (e: Item) => {
    updateVariable(formData, "station", e);
    setSelectedStationId(e.id.toString());
  };

  const updateVariable = (object: any, propertyName: string, newValue: any) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };

    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      setFormData({ ...object });
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      setFormData({ ...currentObject });
    }
  };

  return (
    <React.Fragment>
      <OlogaPageHeader
        title="Stock List"
        items={[{ label: "Products", url: "/products/search" }]}
      />
      <Card>
        <CardContent>
          <form>
            <Grid container spacing={2} marginBottom={3}>
              {isAdmin && (
                <Grid item xs={3}>
                  <OlogaGenericCombo
                    name="stationVendor"
                    label="Vendors"
                    items={vendors}
                    value={formData.stationVendor || ""}
                    onChange={handleChangeVendor}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <OlogaGenericCombo
                  name="stationEvent"
                  label="Events"
                  items={vendorEvents}
                  value={formData.stationEvent || ""}
                  onChange={handleChangeEvent}
                />
              </Grid>
              {/* <Grid item xs={3}>
                <OlogaGenericCombo
                  name="station"
                  label="Stations"
                  items={stations}
                  value={formData.station || ""}
                  onChange={handleChangeStation}
                />
              </Grid> */}
            </Grid>
            <Grid container spacing={2} marginBottom={3}>
              <Grid item xs={2}>
                <OlogaButton
                  label="Export Stock List"
                  mr={2}
                  onClick={exportReport}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>IMAGE</TableCell>
                    <TableCell>PRODUCT NAME</TableCell>
                    <TableCell>STOCK IN</TableCell>
                    <TableCell>STOCK OUT</TableCell>
                    <TableCell>{`BALANCE (PAGAMIO)`}</TableCell>
                    <TableCell>{`FINAL STOCK (MANUAL COUNT)`}</TableCell>
                    <TableCell>DIFFERENCE</TableCell>
                    <TableCell>ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventProducts.map(
                    ({
                      id,
                      imageUri,
                      name,
                      stockIn,
                      stockOut,
                      finalManualStockCount,
                      finalStockCountPagamio,
                      stockCountDifference,
                    }) => (
                      <TableRow key={id + name + stockIn}>
                        <TableCell width={30}>
                          <img
                            src={imageUri}
                            width="30px"
                            height="auto"
                            alt="product-img"
                          />
                        </TableCell>
                        <TableCell>{name}</TableCell>
                        <TableCell> {stockIn}</TableCell>
                        <TableCell> {stockOut}</TableCell>
                        <TableCell>{finalManualStockCount}</TableCell>
                        <TableCell>{finalStockCountPagamio}</TableCell>
                        <TableCell>{stockCountDifference}</TableCell>
                        <TableCell>
                          {
                            <VisibilityOutlinedIcon
                              onClick={() => {
                                setSelectedEventProductMetadata({
                                  vendorId: userContext?.vendorId,
                                  eventId: selectedEventId,
                                  eventName: selectedEventName,
                                  eventProductId: id,
                                  productName: name,
                                  imageUri: imageUri,
                                  stockIn: stockIn,
                                  stockOut: stockOut,
                                  balance: finalStockCountPagamio,
                                  manualStockCount: finalManualStockCount,
                                });
                                setOpenDetailsModal(true);
                              }}
                            />
                          }
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </form>
        </CardContent>
      </Card>
      <StockDetailModal
        open={openDetailsModal}
        onClose={() => {
          setOpenDetailsModal(false);
        }}
        stockMetadata={selectedEventProductMetadata}
      />
    </React.Fragment>
  );
};

export default StockList;
