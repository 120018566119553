import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CardContent,
  Grid,
  Modal,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
} from "@mui/material";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import http from "../../utils/axios";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
import SubmitButton from "../../components/Buttons/SubmitButton";
import Item from "../../components/Combos/Item";
import { Formik } from "formik";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import { toast } from "react-toastify";
import OlogaSubmitButton from "../../components/Buttons/OlogaSubmitButton";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import SyncIcon from "@mui/icons-material/Sync";
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function CommissionPage() {
  const [event, setEvent] = useState();
  const [items, setItems] = useState([]);
  const [events, setEvents] = useState([{ id: 0, name: "" }]);
  const [uri, setUri] = useState("/commission/q?");
  const [eventId, setEventId] = useState(0);
  const [eventName, setEventName] = useState("");
  const [mode, setMode] = useState("");
  const [tax, setTax] = useState("");
  const [show, setShow] = useState(false);
  const [showBtnComm, setShowBtnComm] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Commission Name",
      flex: 1,
      editable: false,
    },
    {
      field: "tax",
      headerName: "Commission Tax",
      flex: 1,
      editable: false,
    },
    {
      field: "totalSales",
      headerName: "Total Sales",
      flex: 1,
      editable: false,
    },
    {
      field: "amount",
      headerName: "Commission Pagamio",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<SyncIcon />}
          onClick={() => {
            console.log(params);
            console.log("DTO", { id: params.id, ...params.row });
            refreshComm({ id: params.id, ...params.row });
          }}
          label="Detail"
        />,
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            const foundEvent = events.find((event) => event.id === params.id);
            setEvent(foundEvent as any);
            setTax(params.row.tax);
            setMode("UPDATE");
            handleOpen();
          }}
          label="Detail"
        />,
      ],
    },
  ];

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #bb86fc",
    boxShadow: 24,
    p: 4,
  };

  const refreshComm = async (values: any) => {
    try {
      const response = await http.put("/commission/refresh", {
        ...values,
      });
      reload();
    } catch (error: any) {
      toast.error(
        "Some Error Occur while refresh Commission : " + error.message
      );
    }
  };
  const updateComm = async (values: any) => {
    try {
      const response = await http.put("/commission", {
        ...values,
      });
      reload();
    } catch (error: any) {
      toast.error(
        "Some Error Occur while refresh Commission : " + error.message
      );
    }
  };
  const submitComm = async (values: any, { setSubmitting }: any) => {
    try {
      if (mode === "UPDATE") {
        const response = await http.put("/commission", {
          ...values,
        });
        setSubmitting(false);
        toast.success("Commission Submited Succefully");
        handleClose();
        reload();
      } else {
        const response = await http.post("/commission", {
          ...values,
          eventId,
          event: { id: eventId },
        });
        setSubmitting(false);
        toast.success("Commission Submited Succefully");
        handleClose();
        reload();
      }
    } catch (error: any) {
      toast.error(
        "Some Error Occur while submit Commission : " + error.message
      );
      setSubmitting(false);
    }
  };

  function reload(): void {
    return window.location.reload();
  }
  function CommissionModal() {
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Select Event to set Commission
            </Typography>
            <br />
            <Formik
              initialValues={{
                tax: tax,
              }}
              onSubmit={submitComm}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <OlogaGenericCombo
                        label="Event"
                        name="event"
                        items={events}
                        value={event || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <OlogaTextfield
                        name="tax"
                        label="Commission Percentage"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        mt={2}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <OlogaSubmitButton label="Save Comm" />
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
        </Modal>
      </div>
    );
  }

  const handleChange = (event: Item) => {
    setEvent(event as any);
    setEventId(Number(event.id));
    setEventName(event.description);
  };

  const getData = async (url: string) => {
    try {
      setShow(false);
      const response = await http.get(url);
      console.log("RESPONSE", response);
      setItems(response.data.content);
      setShow(true);
      setShowBtnComm(true);
    } catch (error) {}
  };

  const retriveEvents = async () => {
    const response = await http.get(`/events/names`);
    setEvents(
      response.data.map((r: Item) => {
        return {
          id: r.id,
          description: r.name,
          type: r.type,
          name: "event",
        };
      })
    );
  };

  useEffect(() => {
    retriveEvents();
  }, []);

  return (
    <React.Fragment>
      <OlogaPageHeader title="Commissions" />

      <Card mb={12}>
        <CardContent>
          <Grid container spacing={2} marginBottom={3}>
            <Grid item xs={0}>
              <SubmitButton label={"Refresh"} onClick={() => reload()} />
            </Grid>

            <Grid item xs={6}>
              <SubmitButton
                label={"Set Commission For Event"}
                onClick={handleOpen}
              />
            </Grid>
          </Grid>

          <Divider my={6} />

          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card mb={6}>
                <Paper>
                  <OlogaDataGrid url={"/commission/q"} columns={columns} />
                </Paper>
              </Card>
            </Grid>
          </Grid>
          {/* </form> */}
          <div>
            <CommissionModal />
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default CommissionPage;
